import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/task',
    name: 'task',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/notwx',
    name: 'Not',
    component: () => import('@/views/notwx.vue')
  },
  {
    path: '/binding',
    name: 'binding',
    component: () => import('@/views/phonenum/index.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('@/views/Message.vue')
  },
  {
    path: '/pubshare',
    name: 'pubshare',
    component: () => import('@/views/share/share.vue')
  },
  {
    path: '/pubedit',
    name: 'pubedit',
    component: () => import('@/views/share/editshare.vue')
  },
  {
    path: '/su',
    name: 'su',
    component: () => import('@/components/success.vue')
  },
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/newhome.vue')
  }
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  routes
})

//不是微信跳转
/*router.beforeEach((to,from,next)=>{
  const ua = navigator.userAgent.toLowerCase();
    if (to.path!=='/notwx' && !(ua.match(/MicroMessenger/i)=="micromessenger")){
      next("/notwx");
    }else {
      next();
    }

});*/


export default router
