import Vue from 'vue'
import Vuex from 'vuex'
import getters from "@/store/getters";
import addlist from "@/store/addlist";
//import time from "@/store/time";

Vue.use(Vuex)

let store =  new Vuex.Store({
  modules: {
    addlist,
    //time,
  },
  getters,
})
export default store
